import { Component } from '@angular/core';

@Component({
  selector: 'amtrust-pages',
  template: `<router-outlet></router-outlet>`
})
export class PagesComponent {
  constructor() {
    console.log('Pages Constructor');
  }
}
