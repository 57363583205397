import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { SuccessResponse } from '../models/success.model'

@Injectable()
export class SaveServices {

    private httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    constructor(
        private httpClient: HttpClient
    ) { }

    saveComment(comment: string,related_to:String): Observable<SuccessResponse> {
        const subject = new Subject<SuccessResponse>();
        const url = `${environment.apiurl + environment.saveComment}`;
        //const body = {"imei-number":searchText,"companyContext":{"companyId":environment.companyId}};
        const body ={"commentcontent":comment,"related_to":related_to};
        console.log(body);
        this.httpClient.post<SuccessResponse>(
            url,
            body,
            {
                headers: this.httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<SuccessResponse>) => {
                if (response && response.body) {
                    console.log(response.body);
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();
    }
}