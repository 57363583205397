import { Inject, Injectable } from "@angular/core";
import { CognitoUtil } from "./cognito.service";
import { AuthenticationDetails, CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { RegistrationUser, NewPasswordUser } from "../../models/aws.model";
import * as AWS from "aws-sdk/global";

@Injectable()
export class UserRegistrationService {

    constructor(@Inject(CognitoUtil) public cognitoUtil: CognitoUtil) { }

    register(user: RegistrationUser, callbackFunction: Function): void {
        let attributeList = [];

        let dataEmail = {
            Name: 'email',
            Value: user.email
        };
        /*        
                let dataGivenName = {
                    Name: 'given_name',
                    Value: user.given_name
                };
                let dataFamilyName = {
                    Name: 'family_name',
                    Value: user.family_name
                };
                let dataPhoneNumber = {
                    Name: 'phone_number',
                    Value: user.phone_number
                };
        */
        attributeList.push(new CognitoUserAttribute(dataEmail));
        /*
                attributeList.push(new CognitoUserAttribute(dataGivenName));
                attributeList.push(new CognitoUserAttribute(dataFamilyName));
                attributeList.push(new CognitoUserAttribute(dataPhoneNumber));
        */

        this.cognitoUtil.getUserPool().signUp(user.email, user.password, attributeList, null, function (err, result) {
            if (err) {
                callbackFunction(null, err.message);
            } else {
                callbackFunction(result, null);
            }
        });
    }

    confirmRegistration(username: string, confirmationCode: string, callbackFunction: Function): void {
        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        let cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmRegistration(confirmationCode, true, function (err, result) {
            if (err) {
                callbackFunction(null, err.message);
            } else {
                callbackFunction(result, null);
            }
        });
    }

    resendCode(username: string, callbackFunction: Function): void {
        let userData = {
            Username: username,
            Pool: this.cognitoUtil.getUserPool()
        };

        let cognitoUser = new CognitoUser(userData);

        cognitoUser.resendConfirmationCode(function (err, result) {
            if (err) {
                callbackFunction(null, err.message);
            } else {
                callbackFunction(result, null);
            }
        });
    }

    newPassword(newPasswordUser: NewPasswordUser, callbackFunction: Function): void {
        console.log(newPasswordUser);
        // Get these details and call
        //cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, this);
        let authenticationData = {
            Username: newPasswordUser.username,
            Password: newPasswordUser.existingPassword,
        };
        let authenticationDetails = new AuthenticationDetails(authenticationData);

        let userData = {
            Username: newPasswordUser.username,
            Pool: this.cognitoUtil.getUserPool()
        };

        let cognitoUser = new CognitoUser(userData);
        cognitoUser.authenticateUser(authenticationDetails, {
            newPasswordRequired: function (userAttributes, requiredAttributes) {
                // User was signed up by an admin and must provide new
                // password and required attributes, if any, to complete
                // authentication.

                // the api doesn't accept this field back
                delete userAttributes.email_verified;
                cognitoUser.completeNewPasswordChallenge(newPasswordUser.password, requiredAttributes, {
                    onSuccess: function (result) {
                        callbackFunction(userAttributes, null);
                    },
                    onFailure: function (err) {
                        callbackFunction(null, err);
                    }
                });
            },
            onSuccess: function (result) {
                callbackFunction(result, null);
            },
            onFailure: function (err) {
                callbackFunction(null, err);
            }
        });
    }
}