export class EventData {
    eventId: string;
    messageText: string;
    displayText: string;
    formResult: any;
    contextData: any;

    constructor(eventId?: string, messageText?: string, formResult?: any, contextData?: any) {
        this.eventId = eventId ? eventId : null;
        this.messageText = messageText ? messageText : null;
        this.formResult = formResult ? formResult : {};
        this.contextData = contextData ? contextData : {};
    }
}