import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { PagesComponent } from './pages.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login/login.component';
//import { ConfirmRegistrationComponent } from './auth/confirm/confirmRegistration.component';
//import { ForgotPasswordStep1Component, ForgotPasswordStep2Component } from './auth/forgotPassword/forgotPassword.component';
import { NewPasswordComponent } from './auth/newPassword/newPassword.component';
//import { RegisterComponent } from './auth/register/register.component';
//import { ResendCodeComponent } from './auth/resendCode/resendCode.component';
//import { ProfileComponent } from './profile/profile.component';

export const routes: Routes = [
  {
    path: 'pg',
    component: PagesComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: 'login', component: LoginComponent },
      { path: "imei/:imei", component: HomeComponent },
      //{ path: 'confirmRegistration/:username', component: ConfirmRegistrationComponent },
      //{ path: 'forgotPassword', component: ForgotPasswordStep1Component },
      //{ path: 'forgotPassword/:email', component: ForgotPasswordStep2Component },
      { path: 'newPassword', component: NewPasswordComponent }
      //{ path: 'register', component: RegisterComponent },
      //{ path: 'resendCode', component: ResendCodeComponent }
      //{ path: 'profile', component: ProfileComponent }
    ]
  }
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
