import { Injectable } from "@angular/core";
import { CognitoUtil } from "./cognito.service";

@Injectable()
export class UserParametersService {

    constructor(public cognitoUtil: CognitoUtil) { }

    getParameters(callbackFunction: Function): void {
        let cognitoUser = this.cognitoUtil.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession(function (err, session) {
                if (err) {
                    console.log("UserParametersService: Couldn't retrieve the user");
                } else {
                    cognitoUser.getUserAttributes(function (err, result) {
                        if (err) {
                            console.log("UserParametersService: in getParameters: " + err);
                            callbackFunction(null);
                        } else {
                            callbackFunction(result);
                        }
                    });
                }
            });
        } else {
            callbackFunction(null);
        }
    }
}