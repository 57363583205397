import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { ChatMessage } from '../models/chatmessage.model';

@Injectable()
export class ConversationService {

    constructor(
        private httpClient: HttpClient
    ) { }

    getChatMessages(episodeId: string): Observable<ChatMessage[]> {
        const subject = new Subject<ChatMessage[]>();

        const url = `${environment.apiurl + environment.getMessages}`;
        const body = {
            episodeId: episodeId
        };
        this.httpClient.post<ChatMessage[]>(
            url,
            body,
            {
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<ChatMessage[]>) => {
                if (response && response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log("error");
                console.log(err);
            }
        )
        return subject.asObservable();

    }
}