import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { SearchResponse } from '../../app/models/searchresponse.model'

@Injectable()
export class SearchService {

    private httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    constructor(
        private httpClient: HttpClient
    ) { }

    search(searchText: string): Observable<SearchResponse> {
        const subject = new Subject<SearchResponse>();
        const url = `${environment.apiurl + environment.searchApi}`;
        const body = { "imei-number": searchText, "companyContext": { "companyId": environment.companyId } };
        this.httpClient.post<SearchResponse>(
            url,
            body,
            {
                headers: this.httpHeaders,
                observe: 'response',
                reportProgress: true
            }
        ).subscribe(
            (response: HttpResponse<SearchResponse>) => {
                if (response && response.body) {
                    subject.next(response.body);
                }
            },
            (err: HttpErrorResponse) => {
                console.log(err);
            }
        )
        return subject.asObservable();
    }
}